<template>

  <Box cmpt="LabelValue" >
    <Row>
      <Column :width="labelWidth">
        <Label>
          <slot name="label"></slot>
        </Label>
      </Column>
      <Column :width="15 - labelWidth">
        <Value :fontSize="valueFontSize">
          <slot name="value"></slot>
        </Value>
      </Column>
    </Row>

  </Box>

</template>

<script>

import { mapGetters } from 'vuex'

import Box from "@/portals/shared/library/box/Box.vue";
import Row from "@/portals/shared/library/box/Row.vue";
import Column from "@/portals/shared/library/box/Column.vue";
import Label from "@/portals/shared/library/label/Label.vue";
import Value from "@/portals/shared/library/value/Value.vue";

export default {
  name: 'portals-shared-library-labelvalue-labelvalue',
  components: {
    Box, Row, Column,
    Label, Value,
  },
  props: {
    align: { type: String, default: "left" },
    valueFontSize: { type: String, default: "small" },
    labelWidth: { type: Number, default: 3 },
  },
  data() {
    return {
    }
  },
  computed: {
    ...mapGetters([
                   'domain',
                 ]),
  },
  methods: {
    alignment: function() {
      if (this.align == "left") {
        return "rc-text-left";
      }
      if (this.align == "right") {
        return "rc-text-right";
      }
      return "rc-text-centre";
    }
  },
}
</script>