
import ContentUtils from '@/utils/ContentUtils.js';
import StringUtils from '@/utils/StringUtils.js';

export default class Utils {

  static SortByLast = function(a,b) {
    var aLast = StringUtils.toString(a.last);
    var bLast = StringUtils.toString(b.last);
    
    if (aLast.toLowerCase() > bLast.toLowerCase()) {
      return 1;
    }
    if (aLast.toLowerCase() < bLast.toLowerCase()) {
      return -1;
    }
    return 0;
  }

  static SortByFirst = function(a,b) {
    var afirst = StringUtils.toString(a.first);
    var bfirst = StringUtils.toString(b.first);

    if (afirst.toLowerCase() > bfirst.toLowerCase()) {
      return 1;
    }
    if (afirst.toLowerCase() < bfirst.toLowerCase()) {
      return -1;
    }
    return 0;
  }
  
  static address = function(user) {
    if (!StringUtils.isEmpty(user.address)) {
      return ContentUtils.unescape(user.address);
    }
    if (user.street) {
      return ContentUtils.unescape(user.street);
    }
    return "";
  }

  static city = function(user) {
    return ContentUtils.unescape(user.city);
  }
  
  static province = function(user) {
    return ContentUtils.unescape(user.jurisdiction);
  }
  
  static country = function(user) {
    return ContentUtils.unescape(user.country);
  }
  
  static postal = function(user) {
    return ContentUtils.unescape(user.postal);
  }
  
  static phoneNumber = function(user) {
    return ContentUtils.unescape(user.phone);
  }

  static email = function(user) {
    return ContentUtils.unescapeEmail(user.email);
  }
  
  static first = function(user) {
    return ContentUtils.unescape(user.first);
  }
  
  static last = function(user) {
    return ContentUtils.unescape(user.last);
  }
  
  static role = function(user) {
    if (!user || !user.employee || !user.employee.UserRoleMap) {
      return "";
    }
    var roleDisplay = "";
    var roles = ContentUtils.ROLES();
    for (var i = 0; i < roles.length; i++) {
      var role = roles[i];
      var hasRole = user.employee.UserRoleMap[role['@rid']];
      
      if (hasRole) {
        if (!StringUtils.isEmpty(roleDisplay)) {
          roleDisplay += ", "
        }
        roleDisplay += role['name'];
      }
      
    }
    return roleDisplay;
  };
}
