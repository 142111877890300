<template>
  <Row v-if="isReady">
    <Column :width="15">
      <div id="detailsModal" class="rc-modal-dialog" :key="redraw">
        <!-- Modal content -->
        <div class="rc-modal-content-dialog">
          <div class="rc-modal-header-dialog">
            <Box>
              <WorkingDisplayRow v-if="this.showWorking"></WorkingDisplayRow>

              <Row 
                v-for="(row, rowIndex) in viewHeaderData" 
                :key="rowIndex" >
                  <CellFactory 
                    :rowData="row.data"
                    :showMissingRequirements="showMissingRequirements"
                    v-on:withValue="withValue"
                    v-on:withButtonPressed="withButtonPressed"
                    />
              </Row>
            </Box>
          </div>

          <div class="rc-modal-body-dialog">
            <div class="rc-modal-body-inner-dialog rc-border-thin">
              <DisplayView 
                :userId="Contents.baseDecode(this.userId)" 
                :showButtons="false"
                :isAdmin="isAdmin">
              </DisplayView>
            </div>
          </div>

          <div class="rc-modal-footer-dialog">
            <Box>
              <Row 
                v-for="(row, rowIndex) in viewFooterData" 
                :key="rowIndex" >
                  <CellFactory 
                    :rowData="row.data"
                    :showMissingRequirements="showMissingRequirements"
                    v-on:withValue="withValue"
                    v-on:withButtonPressed="withButtonPressed"
                    />
              </Row>
            </Box>
          </div>
        </div>
      </div>
    </Column>
  </Row>
</template>

<script>

import { mapActions, mapGetters } from 'vuex';

import ConnectionUtils from '@/utils/ConnectionUtils.js';
import ConstUtils from '@/utils/ConstUtils.js';
import ContentUtils from '@/utils/ContentUtils.js';
import StringUtils from '@/utils/StringUtils.js';

import MC from "@/domain/session/MC.js";

import Column from "@/portals/shared/library/box/Column.vue";
import Row from "@/portals/shared/library/box/Row.vue";
import Box from "@/portals/shared/library/box/Box.vue";
import CellFactory from "@/portals/shared/cell/CellFactory.vue";

import DisplayView from '../../Details.vue';
import TitleDisplayRow from "@/portals/shared/cell/title/TitleDisplayRow";
import ButtonDisplayRow from "@/portals/shared/cell/button/ButtonDisplayRow";
import BoxDisplayRow from "@/portals/shared/cell/box/BoxDisplayRow";
import RowDisplayRow from "@/portals/shared/cell/row/RowDisplayRow";
import StringDisplayRow from "@/portals/shared/cell//string/StringDisplayRow";

import WorkingDisplayRow from '@/portals/shared/views/working/WorkingDisplayRow.vue';

export default {
  name: "employee-list-details-display-view",
  components: {
    Column, Row, 
    Box,
    CellFactory,
    DisplayView,
    WorkingDisplayRow,
  },
  props: {
    userId: { type: String, default: "" },
    isAdmin: { type: Boolean, default: false },
  },
  data() {
    return {
      redraw: 1,
      
      MC: new MC(),
      Strings: StringUtils,
      Contents: ContentUtils,
      Consts: ConstUtils,

      showMissingRequirements: false,
      viewHeaderData: [],
      viewFooterData: [],

      isReady: false,
      showWorking: false,
    };
  },
  computed: {
    ...mapGetters([
                   'auth_connected',
                   'auth_socket_status',
                   'signin_event',
                   'auth_client',
                   'domain',
                   ]),
  },
  watch: {
    auth_connected() {
      ConnectionUtils.sendSignInEvent(this);
    },
    auth_socket_status() {
      ConnectionUtils.displayStatus(this);
    },
    signin_event() {
      ConnectionUtils.createDomainSession(this);
    }
  },
  mounted: function () {
    ConnectionUtils.ensureConnection(this);
  },
  methods: {
    ...mapActions([
                   'AuthStorage_updateCompany',
                   'addListenerAction',
                   'removeListenerAction',
                   'updateDomain',
                   ]),

    start: function() {
      window.onclick = this.onClick;

      var id = this.Contents.baseDecode(this.userId);
      var user = this.domain.users().findById(id);
      if (user) {
        //
      }
      //var employee = this.domain.employees().findById();

      var buttonRow = new RowDisplayRow()
                      .withChild(new StringDisplayRow().withWidth("13"));
      buttonRow
              // .withChild(new ButtonDisplayRow().withWidth("2").withName("Print/Download").withField("Print").withId(id))
              // .withChild(new ButtonDisplayRow().withWidth("1").withName("Email").withField("Email").withId(id))
              // .withChild(new ButtonDisplayRow().withWidth("2").withName("+ Remarks").withField("Remarks").withId(id))
              .withChild(new ButtonDisplayRow().withWidth("2").withName("Full Screen").withField("Full").withId(this.userId))
              ;

      this.viewHeaderData.push(new BoxDisplayRow()
              .withBorder(true)
              .withChild(buttonRow)
            );
      this.viewHeaderData.push(new TitleDisplayRow().withTitle("User Details"));
      this.viewFooterData.push(new BoxDisplayRow()
                  .withBorder(true)
                  .withChild(
                    new RowDisplayRow()
                      .withChild(new StringDisplayRow().withWidth("14"))
                      .withChild(new ButtonDisplayRow().withWidth("1").withName("Close").withField("DetailsClose"))
                  )
              );
      this.isReady = true;
    },

    onClick: function(event) {
      var modal = document.getElementById("detailsModal");
      if (event.target == modal) {
        var data = {};
        data['id'] = this.inspectionId;
        data['field'] = "DetailsClose"
        this.$emit('click', data);
      }
    },
    
    withButtonPressed: function(buttonData) {
      if (buttonData["field"] === "DetailsClose") {
        this.$emit("withButtonPressed", buttonData);
      }
      
      if (buttonData["field"] === "Repair") {
        this.$emit("withButtonPressed", buttonData);
      }
      
      if (buttonData["field"] === "Print") {
        var inspection = this.domain.inspections().findById(buttonData["id"]);
        var url = ContentUtils.escape(inspection.pdfUrl());
        url = url.replace("#", "%23");
        window.open(url, '_blank');
      }

      if (buttonData["field"] === "Email") {
        this.$emit("withButtonPressed", buttonData);
      }

      if (buttonData["field"] === "Remarks") {
        this.$emit("withButtonPressed", buttonData);
      }

      if (buttonData["field"] === "Full") {
        this.$emit("withButtonPressed", buttonData);
      }
    },

    withValue: function(valueData) {
      this.$emit("withValue", valueData);
    },

    displayProgress: function(msg) {
      this.callback(msg);
    },

    callback: function(msg) {
      if (msg) {
        //
      }
    },
    
  },
}
</script>