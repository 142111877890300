import RcTableViewUtils from "../../../../../shared/table/RcTableViewUtils";
import BoxDisplayRow from "@/portals/shared/cell/box/BoxDisplayRow";
import RowDisplayRow from "@/portals/shared/cell/row/RowDisplayRow";
import StringDisplayRow from "@/portals/shared/cell//string/StringDisplayRow";
// import StringUpdateRow from "@/portals/shared/cell/string/StringUpdateRow.js";
import StringListUpdateRow from "@/portals/shared/cell/string/list/StringListUpdateRow.js";
import LabelValueDisplayRow from "@/portals/shared/cell//label/LabelValueDisplayRow";
import TitleDisplayRow from "@/portals/shared/cell/title/TitleDisplayRow";
import ButtonDisplayRow from "@/portals/shared/cell/button/ButtonDisplayRow";
import SpacerDisplayRow from "@/portals/shared/cell/spacer/SpacerDisplayRow";
// import StringUtils from '@/utils/StringUtils.js';
import StatusDisplayRow from "@/portals/shared/cell/status/StatusDisplayRow.js";
import DateUpdateRow from "@/portals/shared/cell/date/DateUpdateRow.js";

import MC from "@/domain/session/MC.js";
import ContentUtils from '@/utils/ContentUtils.js';
import StringUtils from '@/utils/StringUtils.js';

export default class ListViewUtils extends RcTableViewUtils {

  constructor(model, tableData) {
    super(model, tableData);
    this.MC = new MC();
    this.pages = [];
    this.Strings = new StringUtils();
  }

  start() {
    if (this.model().hasError()) {
      this.renderError();  
    }

    if (this.model().isStatePageLoading()) {
      super.start();
      return;
    }

    this.renderHeader(this.model().totalItems());

    if (this.model().isStateContentLoading()) {
      super.start();
      return;
    }

    if (this.model().isStateContentLoaded()) {
      this.renderTimesheets();
    }

    this.renderFooter(this.model().totalItems());
  }

  renderTimesheets() {
    var box = new BoxDisplayRow()
      .withBorder(true);
    box
      .withChild(new RowDisplayRow()
        .withBackgroundTitle()
        .withChild(new TitleDisplayRow().withWidth("8").withTitle("Details"))
        .withChild(new TitleDisplayRow().withWidth("7").withTitle("Hours"))
      );

    var boxContent = new BoxDisplayRow()
    var even = true;
    //var nameFormat = "{0} ({1})";

    var list = this.model().timesheets();
    for (var index = 0; index < list.length; index++) {
      var timesheet = list[index];
      
      var overallStatus = timesheet.defectType();

      var detailsBox = new BoxDisplayRow().withWidth("8")
          .withChild(new LabelValueDisplayRow().withName("Date").withValue(timesheet.dateDisplay()))
          .withChild(new LabelValueDisplayRow().withName("User").withValue(timesheet.employee().find().user().find().fullName()))
          .withChild(new StatusDisplayRow()
            .withValueWidth(5)
            .withLabel(this.MC.Inspection.Details.StatusLabel.value())
            .withValue(overallStatus)
          )
          .withChild(new LabelValueDisplayRow().withName("Start Location").withValue(timesheet.locationStart()))
          .withChild(new LabelValueDisplayRow().withName("End Location").withValue(timesheet.locationEnd()))
          .withChild(new LabelValueDisplayRow().withName("Cycle").withValue(timesheet.cycleDisplay()))
        ;

      if (this.model().isAdmin()) {
        const company = timesheet.company().find();
        detailsBox
          .withChild(new LabelValueDisplayRow().withName("Company").withValue(company.name() + " (" + company.id() + ")"));
      }

      var hoursBox = new BoxDisplayRow().withWidth("7")
          .withChild(new LabelValueDisplayRow().withLabelWidth("5").withName("On Duty Driving").withValue(timesheet.onDutyDrivingHoursDisplay()))
          .withChild(new LabelValueDisplayRow().withLabelWidth("5").withName("On Duty Not Driving").withValue(timesheet.onDutyNotDrivingHoursDisplay()))
          .withChild(new SpacerDisplayRow())
          .withChild(new LabelValueDisplayRow().withLabelWidth("5").withName("On Duty Total").withValue(timesheet.onDutyHoursDisplay()))
          .withChild(new LabelValueDisplayRow().withLabelWidth("5").withName("Off Duty Total").withValue(timesheet.offDutyHoursDisplay()))
          .withChild(new LabelValueDisplayRow().withLabelWidth("5").withName("Total Hours").withValue(timesheet.totalHoursDisplay()))
        ;

      var width = "12";
      if (this.model().isAdmin()) {
        width = "10";
      }

      var actionsRow = new RowDisplayRow()
        .withChild(new StringDisplayRow().withWidth(width))
        .withChild(new ButtonDisplayRow().withWidth("1").withName("Details").withId(timesheet.id()).withField("Details"));

      if (this.model().isAdmin()) {
        actionsRow
          .withChild(new ButtonDisplayRow().withWidth("2").withName("Rebuild PDF").withId(timesheet.id()).withField("Rebuild"));
      }

      actionsRow
        .withChild(new ButtonDisplayRow().withWidth("2").withName("Print/Download").withId(timesheet.id()).withField("Print"));
      
      var buttonsBox = new BoxDisplayRow();
      buttonsBox.withChild(actionsRow);

      boxContent
        .withBorder(false)
        .withChild(
          new BoxDisplayRow()
            .withBorder(true)
            .withEven(even)
            .withChild(
              new RowDisplayRow()
                .withBorder(false)
                  .withChild(detailsBox)
                  .withChild(hoursBox)
                )
            .withChild(buttonsBox)
          );
      even = !even;
    }
    box.withChild(boxContent).done();
    this.add(box);
  }

  renderFooter(totalItems) {
    var query = this.model().panel().$route.query;
    this.pages = this.model().pages();
    this.add(
        new BoxDisplayRow()
        .withBorder(true)
        .withWidth("15")
        .withChild(new RowDisplayRow()
          .withChild(new BoxDisplayRow()
            .withWidth("15")
            .withBorder(true)
            .withChild(new RowDisplayRow()
              .withChild(new LabelValueDisplayRow().withWidth("4").withLabel("Showing").withValue(this.createPageInfo(totalItems, query)))
              .withChild(new StringDisplayRow().withWidth("7"))
              .withChild(new ButtonDisplayRow().withWidth("1").withName("<").withField("PreviousPage"))
              .withChild(new StringListUpdateRow().withWidth("2")
                                                  .withShowLabel(false)
                                                  .withName("Jump to Page")
                                                  .withField("PageIndex")
                                                  .withDefaultValue(query["page"] ? query["page"] : "1")
                                                  .withOptions(this.pages))
              .withChild(new ButtonDisplayRow().withWidth("1").withName(">").withField("NextPage"))
            )
          )
        )
    );
  }

  renderHeader(totalItems) {
    var query = this.model().panel().$route.query;
    var user = this.model().user();
    var userId = user.id();
    if (user.isNew()) {
      userId = "";
    }
    if (userId) {
      //
    }
    this.add(
      new RowDisplayRow()
      .withChild(
        new BoxDisplayRow()
        .withBorder(true)
        .withWidth("15")
        .withChild(new RowDisplayRow()
          .withChild(new LabelValueDisplayRow().withWidth("1").withLabel("Filters"))
          .withChild(new SpacerDisplayRow().withWidth("13"))
          .withChild(new ButtonDisplayRow().withWidth("1").withName("Refresh").withField("Refresh"))
        )
        .withChild(new RowDisplayRow()
          .withChild(
            new BoxDisplayRow()
            .withWidth("15")
            .withBorder(true)
            .withChild(new RowDisplayRow().withBorder(true)
              .withChild(new LabelValueDisplayRow().withWidth("1").withLabel("Status"))
              .withChild(new ButtonDisplayRow().withWidth("1").withName("All").withKey("allStatus").withField("Status").withDefaultValue("all").withSelected(query["status"] === "all" || !query["status"]))
              .withChild(new ButtonDisplayRow().withWidth("1").withName("Complete").withKey("goodStatus").withField("Status").withDefaultValue("complete").withSelected(query["status"] === "complete"))
              .withChild(new ButtonDisplayRow().withWidth("2").withName("In Progress").withKey("minorStatus").withField("Status").withDefaultValue("inprogress").withSelected(query["status"] === "inprogress"))
              .withChild(new ButtonDisplayRow().withWidth("2").withName("Incomplete").withKey("majorStatus").withField("Status").withDefaultValue("incomplete").withSelected(query["status"] === "incomplete"))
              .withChild(new SpacerDisplayRow().withWidth("5"))
            )
            .withChild(new RowDisplayRow().withBorder(true)
              .withChild(new LabelValueDisplayRow().withWidth("1").withLabel("Time"))
              .withChild(new ButtonDisplayRow().withWidth("1").withName("All").withKey("allTime").withField("Time").withDefaultValue("all").withSelected(query["time"] === "all"))
              .withChild(new ButtonDisplayRow().withWidth("1").withName("Today").withKey("todayTime").withField("Time").withDefaultValue("today").withSelected(query["time"] === "today"))
              .withChild(new ButtonDisplayRow().withWidth("2").withName("Yesterday").withKey("yesterdayTime").withField("Time").withDefaultValue("yesterday").withSelected(query["time"] === "yesterday"))
              .withChild(new ButtonDisplayRow().withWidth("2").withName("Last 2 Weeks").withKey("last2WeeksTime").withField("Time").withDefaultValue("last2Weeks").withSelected(query["time"] === "last2Weeks"))
              .withChild(new ButtonDisplayRow().withWidth("2").withName("This Month").withKey("thisMonthTime").withField("Time").withDefaultValue("thisMonth").withSelected(query["time"] === "thisMonth"))
              .withChild(new ButtonDisplayRow().withWidth("2").withName("Last 30 days").withKey("last30DaysTime").withField("Time").withDefaultValue("last30Days").withSelected(query["time"] === "last30Days"  || !query["time"]))
              .withChild(new DateUpdateRow().withWidth("4").withName("Show Hours On or Before").withKey("pickTime").withField("Date").withDefaultValue(isNaN(query["time"]) ? "" : query["time"] ))
            )
            .withChild(new RowDisplayRow().withBorder(true)
              .withChild(new StringListUpdateRow().withWidth("5").withName("User").withKey("pickUser").withField("User").withDefaultValue(userId).withOptions(this.userList()).withShowClear(true))
            )
          )
        )
        .withChild(new RowDisplayRow()
          .withChild(new BoxDisplayRow()
            .withWidth("15")
            .withBorder(true)
            .withChild(new RowDisplayRow()
              .withChild(new LabelValueDisplayRow().withWidth("4").withLabelWidth("5").withLabel("Showing").withValue(this.createPageInfo(totalItems, query)))
              .withChild(new StringDisplayRow().withWidth("4"))
              .withChild(new StringListUpdateRow().withWidth("3")
                                                  .withName("Items/Page")
                                                  .withField("PageSize")
                                                  .withShowLabel(false)
                                                  .withDefaultValue(this.model().pageSize(query))
                                                  .withOptions(ContentUtils.PAGE_SIZES()))
              .withChild(new ButtonDisplayRow().withWidth("1").withName("<").withField("PreviousPage"))
              .withChild(new StringListUpdateRow().withWidth("2")
                                                  .withShowLabel(false)
                                                  .withName("Jump to Page")
                                                  .withField("PageIndex")
                                                  .withDefaultValue(query["page"] ? query["page"] : "1")
                                                  .withOptions(ContentUtils.PAGE_INDEXES(totalItems, this.model().pageSize(query))))
              .withChild(new ButtonDisplayRow().withWidth("1").withName(">").withField("NextPage"))
            )
          )
        )
      )
    );

    // if (!user.isNew()) {
    //   var box = new BoxDisplayRow().withBorder(true);
    //   var title = StringUtils.format("Timesheets for {0}", [user.fullName()]);
    //   box.withChild(new RowDisplayRow()
    //       .withBackgroundTitle()
    //       .withChild(new TitleDisplayRow().withWidth("15").withTitle(title))
    //     );
    //   this.add(box);
    // }
  }

}