import StringDisplayRow from "@/portals/shared/cell//string/StringDisplayRow";
import BoxDisplayRow from "@/portals/shared/cell/box/BoxDisplayRow";
import RowDisplayRow from "@/portals/shared/cell/row/RowDisplayRow";
import RcTableViewUtils from "../../../../../shared/table/RcTableViewUtils";
import StringUpdateRow from "@/portals/shared/cell/string/StringUpdateRow.js";
import LabelValueDisplayRow from "@/portals/shared/cell//label/LabelValueDisplayRow";
import ButtonDisplayRow from "@/portals/shared/cell/button/ButtonDisplayRow";
import SpacerDisplayRow from "@/portals/shared/cell/spacer/SpacerDisplayRow";
import StringListUpdateRow from "@/portals/shared/cell/string/list/StringListUpdateRow.js";
import TitleDisplayRow from "@/portals/shared/cell/title/TitleDisplayRow";
// import StringUtils from '@/utils/StringUtils.js';
import StatusDisplayRow from "@/portals/shared/cell/status/StatusDisplayRow.js";
// import DateUpdateRow from "@/portals/shared/cell/date/DateUpdateRow.js";

import MC from "@/domain/session/MC.js";
import ContentUtils from '@/utils/ContentUtils.js';
import StringUtils from '@/utils/StringUtils.js';

export default class ListViewUtils extends RcTableViewUtils {

  constructor(model, tableData) {
    super(model, tableData);
    this.MC = new MC();
    this.pages = [];
  }

  start() {
    if (this.model().isStatePageLoading()) {
      super.start();
      return;
    }

    this.renderHeader(this.model().totalItems());

    if (this.model().isStateContentLoading()) {
      super.start();
      return;
    }

    if (this.model().isStateContentLoaded()) {
      this.renderItems();
    }

    this.renderFooter(this.model().totalItems());
  }

  renderItems() {
    var box = new BoxDisplayRow()
      .withBorder(true);
    box
      .withChild(new RowDisplayRow()
        .withBackgroundTitle()
        .withChild(new TitleDisplayRow().withWidth("5").withTitle("Details"))
        .withChild(new TitleDisplayRow().withWidth("5").withTitle("Address"))
        .withChild(new TitleDisplayRow().withWidth("5").withTitle("Lisense"))
      );

    var boxContent = new BoxDisplayRow()
    var even = true;
    //var nameFormat = "{0} ({1})";

    var list = this.model().users();
    for (var index = 0; index < list.length; index++) {
      var user = list[index];
      // var userId = user.id();
      var employee = user.employee();
      const company = employee.company().find();
  
      var hint = "";
      
      if (employee.isStateInvited()) {
        hint = "Has not signed in since receiving invite email";
      }
      if (employee.isStateInactive()) {
        hint = "Can no longer sign in";
      }
      if (employee.isStateNotInvited()) {
        hint = "Has not received an invite email";
      }

      var detailsBox = new BoxDisplayRow().withWidth("5")
          .withChild(new LabelValueDisplayRow().withName("Name").withValue(user.fullName()))
          .withChild(new StatusDisplayRow().withName("Status").withValue(user.employee().state()).withHint(hint))
          .withChild(new LabelValueDisplayRow().withName("Email").withValue(user.email()))
          .withChild(new LabelValueDisplayRow().withName("Roles").withValue(user.rolesDisplay()))
          .withChild(new LabelValueDisplayRow().withName("Last Sign In").withValue(user.lastSignInV2().displayAs_YYYY_MM_DD_HH_MM_SS_A("<Has not Signed In>")))
        ;

      var hoursBox = new BoxDisplayRow().withWidth("5")
          .withChild(new LabelValueDisplayRow().withLabelWidth("5").withName("Street").withValue(user.address()))
          .withChild(new LabelValueDisplayRow().withLabelWidth("5").withName("City").withValue(user.city()))
          .withChild(new LabelValueDisplayRow().withLabelWidth("5").withName("Province").withValue(user.jurisdiction()))
          .withChild(new LabelValueDisplayRow().withLabelWidth("5").withName("Country").withValue(user.country()))
          .withChild(new LabelValueDisplayRow().withLabelWidth("5").withName("Postal Code").withValue(user.postal()))
        ;

      var licenseClass = user.licenseClass();
      if (StringUtils.isEmpty(licenseClass)) {
        licenseClass = "Missing";
      }
      var licenseBox = new BoxDisplayRow().withWidth("5");
      if (user.isLicenseClassSet()) {
        licenseBox
          .withChild(new LabelValueDisplayRow().withLabelWidth("5").withName("Class").withValue(licenseClass))
          ;
      } else {
        licenseBox
          .withChild(new StatusDisplayRow().withLabelWidth("5").withName("Class").withValue("ExpireSoon").withObject("Missing"))
        ;
      }

      var licenseDate = user.licenseExpiryDateDisplay();
      if (StringUtils.isEmpty(licenseDate)) {
        licenseDate = "Missing";
      }
      if (user.hasLicenseExpired()) {
        licenseBox
          .withChild(new StatusDisplayRow().withLabelWidth("5").withName("Expiry Date").withValue("Expired").withObject(licenseDate).withHint("Expired"))
        ;
      } else if (user.willLicenseExpireInWeeks(4)) {
        licenseBox
          .withChild(new StatusDisplayRow().withLabelWidth("5").withName("Expiry Date").withValue("ExpireSoon").withObject(licenseDate).withHint("Expiring Soon"))
        ;
      } else if (user.willLicenseExpireInWeeks(1)) {
        licenseBox
          .withChild(new StatusDisplayRow().withLabelWidth("5").withName("Expiry Date").withValue("ExpireNext").withObject(licenseDate).withHint("Expiring this week"))
        ;
      } else {
        licenseBox
          .withChild(new StatusDisplayRow().withLabelWidth("5").withName("Expiry Date").withValue("ExpireSoon").withObject(licenseDate))
        ;
      }

      var width = 0;
      var spacerRow = new StringDisplayRow();
      var actionsRow = new RowDisplayRow()
        .withChild(spacerRow)
        //.withChild(new ButtonDisplayRow().withWidth("1").withName("Details").withId(userId).withField("Details"))
        ;

      width = width + 4;

      if (company.hasTimesheets() && !(employee.isStateNotInvited() || employee.isStateInvited())) {
        actionsRow
          .withChild(new ButtonDisplayRow().withWidth("2").withName("Hours of Service").withId(user.id()).withField("Hours"))
      } else {
        actionsRow
          .withChild(new SpacerDisplayRow().withWidth("2"));
      }

      if (company.hasInspections() && !(employee.isStateNotInvited() || employee.isStateInvited())) {
        actionsRow
          .withChild(new ButtonDisplayRow().withWidth("2").withName("Inspections").withId(user.id()).withField("Inspections"));
      } else {
        actionsRow
          .withChild(new SpacerDisplayRow().withWidth("2"));
      }

      if (user.employee().isStateActive()) {
        width = width + 4;
        actionsRow
          .withChild(new ButtonDisplayRow().withWidth("2").withName("Remove").withId(user.id()).withField("UserRemoveConfirm"))
          .withChild(new ButtonDisplayRow().withWidth("2").withName("Reset Password").withId(user.id()).withField("PasswordResetConfirm"))
          ;
      }

      if (user.employee().isStateInvited()) {
        width = width + 4;
        actionsRow
          .withChild(new ButtonDisplayRow().withWidth("2").withName("Remove").withId(user.id()).withField("UserRemoveConfirm"))
          .withChild(new ButtonDisplayRow().withWidth("2").withName("Resend Invite Email").withId(user.id()).withField("Resend"))
          ;
      }

      if (user.employee().isStateNotInvited()) {
        width = width + 4;
        actionsRow
          .withChild(new ButtonDisplayRow().withWidth("2").withName("Remove").withId(user.id()).withField("UserRemoveConfirm"))
          .withChild(new ButtonDisplayRow().withWidth("2").withName("Send Invite Email").withId(user.id()).withField("Send"))
          ;
      }

      if (user.employee().isStateInactive()) {
        width = width + 2;
        actionsRow
          .withChild(new ButtonDisplayRow().withWidth("2").withName("Restore").withId(user.id()).withField("Restore"))
          ;
      }

      width = width + 2;
      actionsRow
        .withChild(new ButtonDisplayRow().withWidth("2").withName("Update").withId(user.id()).withField("UserUpdate"))
        ;
      
      var totalWidth = (15 - width);
      spacerRow.withWidth( totalWidth + "");

      const adminSection = new BoxDisplayRow();
      if (this.model().isAdmin()) {
        var companyName = "";
        if (company.isNew()) {
          companyName = "Missing"
        } else {
          companyName = company.name  + " (" + company.id() + ")";
        }
        adminSection
          .withBorder(true)
          .withChild(new StringDisplayRow().withValue("Admin Section").withAlignLeft())
          .withChild(new LabelValueDisplayRow().withLabelWidth("5").withName("Company").withValue(companyName))
          ;
        adminSection
          .withChild(new LabelValueDisplayRow().withLabelWidth("5").withName("Created By/On").withValue(user.createdBy().find().fullName() + "/" + user.createdDateV2().displayAs_YYYY_MM_DD_HH_MM_SS_A() ))
          .withChild(new LabelValueDisplayRow().withLabelWidth("5").withName("Modified By/On").withValue(user.modifiedBy().find().fullName() + "/" + user.modifiedDateV2().displayAs_YYYY_MM_DD_HH_MM_SS_A() ))
          ;

      }
      var buttonsBox = new BoxDisplayRow();
      buttonsBox.withChild(actionsRow);

      boxContent
        .withBorder(false)
        .withChild(
          new BoxDisplayRow()
            .withBorder(true)
            .withEven(even)
            .withChild(
              new RowDisplayRow()
                .withBorder(false)
                  .withChild(detailsBox)
                  .withChild(hoursBox)
                  .withChild(licenseBox)
              )
            .withChild(adminSection)
            .withChild(buttonsBox)
          );
      even = !even;
    }
    box.withChild(boxContent).done();
    this.add(box);
  }

  renderFooter(totalItems) {
    var query = this.model().panel().$route.query;
    this.pages = this.model().pages();
    this.add(
        new BoxDisplayRow()
        .withBorder(true)
        .withWidth("15")
        .withChild(new RowDisplayRow()
          .withChild(new BoxDisplayRow()
            .withWidth("15")
            .withBorder(true)
            .withChild(new RowDisplayRow()
              .withChild(new LabelValueDisplayRow().withWidth("4").withLabelWidth("5").withLabel("Showing").withValue(this.createPageInfo(totalItems, query)))
              .withChild(new StringDisplayRow().withWidth("7"))
              .withChild(new ButtonDisplayRow().withWidth("1").withName("<").withField("PreviousPage"))
              .withChild(new StringListUpdateRow().withWidth("2")
                                                  .withShowLabel(false)
                                                  .withName("Jump to Page")
                                                  .withField("PageIndex")
                                                  .withDefaultValue(this.model().pageNumber(query))
                                                  .withOptions(this.pages))
              .withChild(new ButtonDisplayRow().withWidth("1").withName(">").withField("NextPage"))
            )
          )
        )
    );
  }

  renderHeader(totalItems) {
    var query = this.model().panel().$route.query;
    var inviteName = "Invite User";
    var fieldName = "Invite";
    if (this.model().hasFeatureSso()) {
      inviteName = "Add User";
      fieldName = "Add";
    }
    this.add(
      new RowDisplayRow()
      .withChild(
        new BoxDisplayRow()
        .withBorder(true)
        .withWidth("15")
        .withChild(new RowDisplayRow()
          .withChild(new LabelValueDisplayRow().withWidth("1").withLabel("Filters"))
          .withChild(new SpacerDisplayRow().withWidth("11"))
          .withChild(new ButtonDisplayRow().withWidth("2").withName(inviteName).withField(fieldName).withBackgroundPrimaryAction())
          .withChild(new ButtonDisplayRow().withWidth("1").withName("Refresh").withField("Refresh"))
        )
        .withChild(new RowDisplayRow()
          .withChild(
            new BoxDisplayRow()
            .withWidth("15")
            .withBorder(true)
            .withChild(new RowDisplayRow()
              .withChild(new LabelValueDisplayRow().withWidth("1").withLabel("Status"))
              .withChild(new ButtonDisplayRow().withWidth("1").withName("All").withKey("all").withField("Status").withDefaultValue("all").withSelected(query["status"] === "all"))
              .withChild(new ButtonDisplayRow().withWidth("1").withName("Active").withKey("active").withField("Status").withDefaultValue("active").withSelected(query["status"] === "active" || !query["status"]))
              .withChild(new ButtonDisplayRow().withWidth("1").withName("Invited").withKey("inactive").withField("Status").withDefaultValue("invited").withSelected(query["status"] === "invited"))
              .withChild(new ButtonDisplayRow().withWidth("2").withName("Waiting to be Invited").withKey("majorStatus").withField("Status").withDefaultValue("notinvited").withSelected(query["status"] === "notinvited"))
              .withChild(new ButtonDisplayRow().withWidth("1").withName("Inactive").withKey("inactive").withField("Status").withDefaultValue("inactive").withSelected(query["status"] === "inactive"))
              .withChild(new SpacerDisplayRow().withWidth("8"))
            )
            .withChild(new RowDisplayRow().withBorder(false)
              .withChild(new LabelValueDisplayRow().withWidth("1").withLabel("Search"))
              .withChild(new StringUpdateRow().withWidth("5")
                  .withShowLabel(false).withField("Search")
                  .withKey("Search").withName("Search")
                  .withDefaultValue(query["search"] ? query["search"] : ""))
              .withChild(new ButtonDisplayRow().withWidth("1").withName("Go").withKey("Search").withField("Search").withSelected(false))
              .withChild(new ButtonDisplayRow().withWidth("1").withName("Clear").withKey("Search").withField("Clear").withSelected(false))
              .withChild(new SpacerDisplayRow().withWidth("7"))
            )
          )
        )
        .withChild(new RowDisplayRow()
          .withChild(new BoxDisplayRow()
            .withWidth("15")
            .withBorder(true)
            .withChild(new RowDisplayRow()
              .withChild(new LabelValueDisplayRow().withWidth("4").withLabelWidth("5").withLabel("Showing").withValue(this.createPageInfo(totalItems, query)))
              .withChild(new StringDisplayRow().withWidth("4"))
              .withChild(new StringListUpdateRow().withWidth("3")
                                                  .withName("Items/Page")
                                                  .withField("PageSize")
                                                  .withShowLabel(false)
                                                  .withDefaultValue(this.model().pageSize(query))
                                                  .withOptions(ContentUtils.PAGE_SIZES()))
              .withChild(new ButtonDisplayRow().withWidth("1").withName("<").withField("PreviousPage"))
              .withChild(new StringListUpdateRow().withWidth("2")
                                                  .withShowLabel(false)
                                                  .withName("Jump to Page")
                                                  .withField("PageIndex")
                                                  .withDefaultValue(query["page"] ? query["page"] : "1")
                                                  .withOptions(ContentUtils.PAGE_INDEXES(totalItems, this.model().pageSize(query))))
              .withChild(new ButtonDisplayRow().withWidth("1").withName(">").withField("NextPage"))
            )
          )
        )
      )
    );
  }

}