<template>
<div style="padding-top: 10px" class="fullscreen">
    
    <Spacer />
    
    <Box>
      <Row>
        <Column :width="4" />
        <Column :width="7">
          <ErrorRow :error="error" />
        </Column>
        <Column :width="4" />
      </Row>
    </Box>
  
    <Box>
     <Row>
       <Column :width="4" />
        <Column :width="7">
         <Box>
          <TitleRow> {{ MC.User.Restore.Title.value() }} </TitleRow>
         </Box>
        </Column>
        <Column :width="4" />
      </Row>
    </Box>
    
    <Box>
      <Row>
        <Column :width="4" />
        <Column :width="7" :showBorder="true">
          <Label align="centre" fontSize="xxxlarge">{{ MC.User.Restore.Confirm.value() }}</Label>
          <Value align="centre" fontSize="large">{{ MC.User.Restore.Message.value([userObj().fullName()]) }}</Value>
          <Spacer />
          <Value align="centre" fontSize="small">{{ MC.User.Restore.InsuranceReminder.value([employeeObj().company().find().name()]) }}</Value>
        </Column>
        <Column :width="4" />
      </Row>
    </Box>
    
    <Box>
      <Row>
        <Column :width="4" />

        <Column :width="7">
          <div v-if="isSending">
            <div class="rc-modal-green">
              <h1>Restoring</h1>
              <p>Please wait...</p>
            </div>
          </div>
          
          <GreenButton :disabled="isSending" v-on:click="remove" label="Restore"/>
          <Button v-on:click="reject" :disabled="isSending">No</button>
        </Column>
        
        <Column :width="4" />
      </Row>
    </Box>
  
</div>
</template>

<script>
import { mapGetters } from 'vuex';

import ConstUtils from '@/utils/ConstUtils.js';
import ContentUtils from '@/utils/ContentUtils.js';

import MC from "@/domain/session/MC.js";

import Box from "@/portals/shared/library/box/Box.vue";
import Row from "@/portals/shared/library/box/Row.vue";
import Column from "@/portals/shared/library/box/Column.vue";
import TitleRow from "@/portals/shared/library/title/TitleRow.vue";
import Spacer from "@/portals/shared/library/spacer/Spacer.vue";
import Label from "@/portals/shared/library/label/Label.vue";
import Value from "@/portals/shared/library/value/Value.vue";

import GreenButton from '@/components/button/GreenButton.vue';
import Button from '@/components/button/BackButton.vue';
import ErrorRow from '@/components/row/ErrorRow.vue';

export default {
  name: 'confirm-remove-employee',
  components: {
    ErrorRow,
    GreenButton,
    Button,
    Box, Row, Column, 
    TitleRow, Spacer, Value, Label,
  },
  props: {
    user: Object,
  },
  data() {
    return {
      error: null,
      isSending: false,
      MC: new MC(),
    };
  },
  
  watch: {
    auth_connected() {
      if (this.auth_connected) {
        this.send()
      }
    },
  },
  
  computed: {
    ...mapGetters(['auth_socket', 
      'auth_connected', 
      'auth_socket_status', 
      'auth_socket_message', 
      'auth_user',
      'auth_client',
      'employees_found',
      'companys_found',
      'users_store',
      'domain',
      ]),
      company: function() {
        if (this.user) {
          var companyRef = this.user.employee.Company[ConstUtils.FIELDS.ID];
          return this.companys_found.map[companyRef];
        }
        return {};
      },
      toCompanyName: function() {
        if (!this.user) {
          return "";
        }
        var companyRef = this.user.employee.Company[ConstUtils.FIELDS.ID];
        var company = this.companys_found.map[companyRef];
        if (company) {
          return ContentUtils.unescape(company.name);
        }
        return "";
      },
      toUserName: function() {
        if (this.user) {
          return this.user.email;
        }
        return "";
      },
  },
  
  methods: {
    reject: function () {
      this.$emit("closeRejected");
    },
    
    remove: function() {
      if (this.auth_connected) {
        this.send();
      } else {
        this.$connect();
      }
    },
    
    userObj: function() {
      return this.domain.users().findById(this.user['@rid']);
    },
    
    employeeObj: function() {
      return this.domain.employees().findById(this.user.employee['@rid']);
    },
    
    send: function() {
      this.isSending = true;
      var employeeData = this.user.employee;
      var event = this.domain.events().employees().restore(employeeData);
      event.send(this.listener);
    },
    
    listener: function(event) {
      this.isSending = false;
      if (event.error()) {
        this.error = event.error();
      } else {
        this.$emit("closeConfirmed");
      }
    },
  },
}
</script>
