<template>
  <Box>
    <SpacerRow />
  
    <Menu v-if="showButtons" page="details" :showBorder="true" :isAdmin="isAdmin" :isAdminList="isAdminList" />
  
    <TitleRow v-if="showButtons">
    User Details
    </TitleRow>
    
    <Row :showBorder="true">
      <Column>
        <EmployeeDetails :userData="userData"/>
      </Column>
    </Row>
      
  </Box>
</template>
<script>
import { mapGetters } from 'vuex'

import ConstUtils   from "@/utils/ConstUtils.js";
import ContentUtils from '@/utils/ContentUtils.js';
import StringUtils from '@/utils/StringUtils.js';

import Box from "@/portals/shared/library/box/Box.vue";
import Row from "@/portals/shared/library/box/Row.vue";
import Column from "@/portals/shared/library/box/Column.vue";

import SpacerRow from "@/portals/shared/library/spacer/SpacerRow.vue";
import TitleRow from "@/portals/shared/library/title/TitleRow.vue";

import EmployeeDetails from "@/views/portal/employees/details/EmployeeDetails.vue";
import Menu from "./Menu.vue"

export default {
  name: "portal-customer-operator-employee-file",
  components: {
    Box,Row,Column, SpacerRow,TitleRow,
    EmployeeDetails, 
    Menu,
  },
  data() {
    return {
      id: this.$route.params["id"],
    };
  },
  props: {
    isAdmin: { type: Boolean, default: false },
    showButtons: { type: Boolean, default: true },
    isAdminList: { type: Boolean, default: false },
    userId: { type: String, default: "" },
  },
  computed: {
    ...mapGetters([
                   'auth_connected',
                   'domain',
                ]),
    userData: function() {
      return this.user.data;
    },
    user: function() {
      return this.employee.user().find();
    },
    
    employeeData: function() {
      return this.employee.data;
    },
    employee: function() {
      if (StringUtils.isNotEmpty(this.userId)) {
        var companyId = ContentUtils.baseDecode(this.$route.params.operatorId);
        const userId = ContentUtils.baseDecode(this.userId);
        var employeeMap = this.domain.employees().findByUserId(userId);
        return employeeMap.findByCompanyId(companyId);
      } else {
        return this.domain.employees().findById(this.id);
      }
    },
  },
  watch: {
    
  },
  mounted: function() {
    if (!this.auth_connected) {
      this.$router.replace({ name: this.$route.query.redirectTo || ConstUtils.ROUTES.SIGNIN });
    }
  },
  methods: {
    
  },
}
</script>