
import RcObject from "@/domain/common/RcObject.js";
import Company from "@/domain/model/company/Company.js";

export default class EmployeeListFilter extends RcObject {
  
  static Fields = {
    PAGINATION: 'pagination',
    STATE_TYPE: 'state',
    COMPLIANCE_TYPE: 'complianceType',
    COMPANY: Company.MODEL_NAME,
    SEARCH_TEXT: 'searchText',
  }
  
  constructor() {
    super({});
  }
  
  modelName() {
    return "EmployeeListFilter";
  }
  
  withCompany(companyId) {
    this.put(EmployeeListFilter.Fields.COMPANY, companyId);
    return this;
  }
  
  withState(state) {
    this.put(EmployeeListFilter.Fields.STATE_TYPE, state);
    return this;
  }
  
  withComplianceType(type) {
    this.put(EmployeeListFilter.Fields.COMPLIANCE_TYPE, type);
    return this;
  }
  
  withPagination(size, number) {
    this.put(EmployeeListFilter.Fields.PAGINATION, { size, number });
    return this;
  }
  
  withSearchText(text) {
    this.put(EmployeeListFilter.Fields.SEARCH_TEXT, { 'text': text });
    return this;
  }
  
  done() {
    //
  }
}