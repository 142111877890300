
import ListViewUtils from "./ListViewUtils";

// import Utils from '@/views/portal/inspections/Utils.js';
import ContentUtils from '@/utils/ContentUtils.js';
import StringUtils from '@/utils/StringUtils.js';
import RcTableViewModel from "../../../../../shared/table/RcTableViewModel";
// import TimesheetMap from '@/domain/model/timesheet/TimesheetMap.js';
import EmployeeMap from "@/domain/model/employee/EmployeeMap.js";
import UserMap from "@/domain/model/user/UserMap.js";

// import Employee from "@/domain/model/employee/Employee.js";
import EmployeeListFilter from "@/domain/model/employee/EmployeeListFilter.js";
// import EmployeeUtils from '@/views/portal/employees/Utils.js';

export default class ListViewModel extends RcTableViewModel {
    
  constructor(panel) {
    super(panel);
    this._companyId = ContentUtils.baseDecode(panel.$route.params.operatorId);
    this._total = 0;
    this._users = [];
    this._pages = [];
    this._userMap = null;
    this._searchText = panel.$route.query.search ? panel.$route.query.search : "";
  }

  start() {
    super.start();
  }

  isAdmin() {
    return this.panel().isAdmin;
  }

  withSearchText(valueData) {
    this._searchText = valueData["value"]
    return this;
  }
  
  searchText() {
    return this._searchText;
  }

  withValue(valueData) {
    super.withValue(valueData);
  }

  withButtonPressed(buttonData) {
    super.withButtonPressed(buttonData);
    if (!buttonData) {
      return;
    }
  }

  buildRowData(tableData) {
    var utils = new ListViewUtils(this, tableData);
    utils.start();
    return this;
  }

  restoreUser(userId) {
    var context = {};
    context["model"] = this;
    
    this.panel().showWorking = true;
    var user = this._userMap.getUser(userId);
    var employeeData = user.employee();
    var event = this.domain().events().employees().restore(employeeData);
    event.send(this.basicRefreshListener, context);
  }

  removeUser(userId) {
    var context = {};
    context["model"] = this;

    this.panel().showWorking = true;
    var user = this._userMap.getUser(userId);
    var employeeData = user.employee();
    var event = this.domain().events().employeeRemove(employeeData);
    event.send(this.basicRefreshListener, context);
  }

  resetPassword(userId) {
    var context = {};
    context["model"] = this;

    this.panel().showWorking = true;
    var user = this._userMap.getUser(userId);
    var event = this.domain().events().users().resetPassword(user.email());
    event.send(this.basicListener, context);
  }

  resendInvite(userId) {
    var context = {};
    context["model"] = this;

    this.panel().showWorking = true;
    var user = this._userMap.getUser(userId);
    var event = this.domain().events().employeeResend(user);
    event.send(this.basicListener, context);
  }

  basicListener(response, context) {
    var model = context.model;
    if (response.error()) {
      model.withError(response.error());
      model.panel().reloadPage();
    }
    model.panel().showWorking = false;
  }

  basicRefreshListener(response, context) {
    var model = context.model;
    model.basicListener(response, context);
    if (response.error()) {
      return;
    }
    model.panel().reloadPage();
  }

  loadEmployees() {
    super.loadItems();
    var query = this.panel().$route.query;
    this.panel().displayProgress("Loading Users");
    var time = query["time"];
    if (!isNaN(time)) {
      time = "(" + time + ")";
    } 
    if (StringUtils.isEmpty(time)) {
      time = "last30Days";
    }
        
    var context = {};
    context["model"] = this;

    var filter = new EmployeeListFilter();
      filter
      .withState(query["status"] ? query["status"] : "active")
      .withPagination(this.pageSize(query), query["page"] ? (query["page"] - 1) : 0)
      .done();

    filter
      .withCompany(this._companyId)
      .done();

    if (!StringUtils.isEmpty(this._searchText)) {
      filter
        .withSearchText(this._searchText)
        .done()
    }

    var event = this.domain().events().employees().list(filter);
    if (this.isAdmin) {
      event.with("signatures", true);
    }
    event.send(this.loadEmployeesListener, context);
  }

  loadEmployeesListener(responseEvent, context) {
    var model = context.model;
    if (responseEvent.error()) {
      model.withError(responseEvent.error());
    } else {
      var payload = responseEvent.payload();
      model._total = payload.count();
      var payloadMap = payload.valuesFor(EmployeeMap.MODEL_NAME);
      var userPayloadMap = payload.valuesFor(UserMap.MODEL_NAME);
      var employeeMap = new EmployeeMap(model.domain(), payloadMap);
      var userMap = new UserMap(model.domain(), userPayloadMap);
      model.update(model, userMap, employeeMap);
    }
    var query = model.panel().$route.query;
    model._pages = ContentUtils.PAGE_INDEXES(model._total, model.pageSize(query))
    model
      .withStateContentLoaded()
      .clearAndBuildTableData()
      .done();
    model.panel().showDetailsIfRequired();
  }

  update(model, userMap, employees) {
    var employeeList = employees.list();
    for (var i = 0; i < employeeList.length; i++) {
      var employee = employeeList[i];
      var user = employee.user();
      var userData = userMap.get(user.id());
      if (userData) {
        userData['employee'] = employee.data;
      }
    }
    if (userMap) {
      var userList = userMap.sortByName();
      model._users = userList;
      model._userMap = userMap;
    }
  }

  company() {
    return this.domain().companys().findById(this._companyId);
  }

  hasFeatureSso() {
    return this.company().features().find().allowsSso();
  }
  
  users() {
    return this._users;
  }

  totalItems() {
    return this._total;
  }

  pages() {
    return this._pages;
  }

}