<template>
  <Row v-if="isReady">
    <Column :width="15">
      <div id="detailsModal" class="rc-modal-dialog" :key="redraw">
        <!-- Modal content -->
        <div class="rc-modal-content-dialog-medium-max">
          <div class="rc-modal-header-dialog">
            <Box>
              <WorkingDisplayRow v-if="this.showWorking"></WorkingDisplayRow>
              <Row 
                v-for="(row, rowIndex) in viewHeaderData" 
                :key="rowIndex" >
                  <CellFactory 
                    :rowData="row.data"
                    :showMissingRequirements="showMissingRequirements"
                    v-on:withValue="withValue"
                    v-on:withButtonPressed="withButtonPressed"
                    />
              </Row>
            </Box>
          </div>

          <div class="rc-modal-body-dialog">
            <div class="rc-modal-body-inner-dialog rc-border-thin">
              <Box>
              <Row 
                v-for="(row, rowIndex) in viewBodyData" 
                :key="rowIndex" >
                  <CellFactory 
                    :rowData="row.data"
                    :showMissingRequirements="showMissingRequirements"
                    v-on:withValue="withValue"
                    v-on:withButtonPressed="withButtonPressed"
                    />
              </Row>
            </Box>
            </div>
          </div>

          <div class="rc-modal-footer-dialog">
            <Box>
              <Row 
                v-for="(row, rowIndex) in viewFooterData" 
                :key="rowIndex" >
                  <CellFactory 
                    :rowData="row.data"
                    :showMissingRequirements="showMissingRequirements"
                    v-on:withValue="withValue"
                    v-on:withButtonPressed="withButtonPressed"
                    />
              </Row>
            </Box>
          </div>
        </div>
      </div>
    </Column>
  </Row>
</template>

<script>

import { mapActions, mapGetters } from 'vuex';

import ConnectionUtils from '@/utils/ConnectionUtils.js';
import ConstUtils from '@/utils/ConstUtils.js';
import ContentUtils from '@/utils/ContentUtils.js';
import StringUtils from '@/utils/StringUtils.js';

import MC from "@/domain/session/MC.js";

import Column from "@/portals/shared/library/box/Column.vue";
import Row from "@/portals/shared/library/box/Row.vue";
import Box from "@/portals/shared/library/box/Box.vue";
import CellFactory from "@/portals/shared/cell/CellFactory.vue";

import TitleDisplayRow from "@/portals/shared/cell/title/TitleDisplayRow";
import ButtonDisplayRow from "@/portals/shared/cell/button/ButtonDisplayRow";
import BoxDisplayRow from "@/portals/shared/cell/box/BoxDisplayRow";
import RowDisplayRow from "@/portals/shared/cell/row/RowDisplayRow";
import DateUpdateRow from "@/portals/shared/cell/date/DateUpdateRow";
import StringDisplayRow from "@/portals/shared/cell/string/StringDisplayRow";
import StringUpdateRow from "@/portals/shared/cell/string/StringUpdateRow";
import StringListUpdateRow from "@/portals/shared/cell/string/list/StringListUpdateRow";
import JurisdictionUpdateRow from "@/portals/shared/cell/vehicle/jurisdiction/JurisdictionUpdateRow.js";
import EmailUpdateRow from "@/portals/shared/cell/email/EmailUpdateRow";
import PhoneNumberUpdateRow from "@/portals/shared/cell/phonenumber/PhoneNumberUpdateRow";
import SpacerDisplayRow from '@/portals/shared/cell/spacer/SpacerDisplayRow';
import ErrorDisplayRow from "@/portals/shared/cell/error/ErrorDisplayRow";

import WorkingDisplayRow from '@/portals/shared/views/working/WorkingDisplayRow.vue';

export default {
  name: "employee-list-update-list-view",
  components: {
    Column, Row, 
    Box,
    CellFactory,
    WorkingDisplayRow,
  },
  props: {
    userId: { type: String, default: "" },
    isAdmin: { type: Boolean, default: false },
    showButtons: { type: Boolean, default: false },
  },
  data() {
    return {
      redraw: 1,
      
      MC: new MC(),
      Strings: StringUtils,
      Contents: ContentUtils,
      Consts: ConstUtils,

      showMissingRequirements: false,
      viewHeaderData: [],
      viewBodyData: [],
      viewFooterData: [],

      isReady: false,
      showWorking: false,

      error: null,

      StringUpdateRow: StringUpdateRow,
      SpacerDisplayRow: SpacerDisplayRow,
    };
  },
  computed: {
    ...mapGetters([
                   'auth_connected',
                   'auth_socket_status',
                   'signin_event',
                   'auth_client',
                   'domain',
                   ]),
  },
  watch: {
    auth_connected() {
      ConnectionUtils.sendSignInEvent(this);
    },
    auth_socket_status() {
      ConnectionUtils.displayStatus(this);
    },
    signin_event() {
      ConnectionUtils.createDomainSession(this);
    }
  },
  mounted: function () {
    ConnectionUtils.ensureConnection(this);
  },
  methods: {
    ...mapActions([
                   'AuthStorage_updateCompany',
                   'addListenerAction',
                   'removeListenerAction',
                   'updateDomain',
                   ]),

    start: function() {
      window.onclick = this.onClick;

      var id = this.Contents.baseDecode(this.userId);
      var user = this.domain.users().findById(id);
      if (user) {
        //
      }
      //var employee = this.domain.employees().findById();

      // var buttonRow = new RowDisplayRow()
      //                 .withChild(new StringDisplayRow().withWidth("13"));
      // buttonRow
      //         // .withChild(new ButtonDisplayRow().withWidth("2").withName("Print/Download").withField("Print").withId(id))
      //         // .withChild(new ButtonDisplayRow().withWidth("1").withName("Email").withField("Email").withId(id))
      //         // .withChild(new ButtonDisplayRow().withWidth("2").withName("+ Remarks").withField("Remarks").withId(id))
      //         // .withChild(new ButtonDisplayRow().withWidth("2").withName("Full Screen").withField("Full").withId(this.userId))
      //         ;

      // this.viewHeaderData.push(new BoxDisplayRow()
      //         .withBorder(true)
      //         .withChild(buttonRow)
      //       );

      if (this.error) {
        var errorBox = new BoxDisplayRow().withBorder(true);
        errorBox
          .withChild(new RowDisplayRow()
            .withChild(new SpacerDisplayRow().withWidth("1"))
            .withChild(new ErrorDisplayRow().withWidth("13").withError(this.error))
            .withChild(new SpacerDisplayRow().withWidth("1"))
          );
        this.viewBodyData.push(errorBox);
      }

      if (user.isNotNew()) {
        var box = new BoxDisplayRow().withBorder(true);
        var title = StringUtils.format("Update User: {0}", [user.fullName()]);
        box.withChild(new RowDisplayRow()
            .withBackgroundTitle()
            .withChild(new TitleDisplayRow().withWidth("15").withTitle(title))
          );
        this.viewHeaderData.push(box);
      } else {
        this.viewHeaderData.push(new TitleDisplayRow().withTitle("Add User"));
      }

      this.viewBodyData.push(new BoxDisplayRow()
                              .withChild(new RowDisplayRow()
                                          .withChild(new BoxDisplayRow().withBorder(true)
                                            .withWidth("8")
                                            .withChild(new TitleDisplayRow().withTitle("Details").withSubtitle(true))
                                            .withChild(new StringUpdateRow()
                                                          .withMandatoryValue(true)
                                                          .withName("First")
                                                          .withField("First")
                                                          .withKey("First"))
                                            .withChild(new StringUpdateRow()
                                                          .withMandatoryValue(true)
                                                          .withName("Last")
                                                          .withField("Last")
                                                          .withKey("Last"))
                                            .withChild(new EmailUpdateRow()
                                                          .withMandatoryValue(true)
                                                          .withName("Email")
                                                          .withField("Email")
                                                          .withKey("email")
                                                        )
                                            .withChild(new PhoneNumberUpdateRow()
                                                          .withMandatoryValue(false)
                                                          .withName("Phone")
                                                          .withField("Phone")
                                                          .withKey("phone"))
                                            )
                                          .withChild(new BoxDisplayRow().withBorder(true)
                                            .withWidth("7")
                                            .withChild(new TitleDisplayRow().withTitle("Role and Employee ID").withSubtitle(true))
                                            .withChild(new StringListUpdateRow()
                                                            .withMandatoryValue(true)
                                                            .withOptions(ContentUtils.ROLES())
                                                            .withField("Role")
                                                            .withName("Role")
                                                            .withKey("role"))
                                            .withChild(new StringUpdateRow()
                                                          .withMandatoryValue(false)
                                                          .withHint("Employee ID is optional unless your organization shares tablets.")
                                                          .withName("Employee ID")
                                                          .withField("employmentId")
                                                          .withKey("employmentId"))
                                          )
                            )
                            .withChild(new RowDisplayRow()
                                          .withChild(new BoxDisplayRow().withBorder(true)
                                            .withWidth("8")
                                              .withChild(new TitleDisplayRow().withTitle("Address").withSubtitle(true))
                                              .withChild(new StringDisplayRow().withValue("This value is used by the app when the Home button is pressed in the App"))
                                              .withChild(new StringUpdateRow()
                                                          .withMandatoryValue(false)
                                                          .withName("No. and Street")
                                                          .withField("street")
                                                          .withKey("street"))
                                              .withChild(new StringUpdateRow()
                                                          .withMandatoryValue(false)
                                                          .withName("City")
                                                          .withField("city")
                                                          .withKey("city"))
                                              .withChild(new JurisdictionUpdateRow()
                                                .withName(this.MC.Vehicle.Add.Jurisdiction.value())
                                                .withField("Province")
                                                .withMandatoryValue(false))
                                              .withChild(new StringListUpdateRow()
                                                            .withOptions(ContentUtils.COUNTRYS())
                                                            .withField("Country")
                                                            .withName("Country")
                                                            .withKey("country"))
                                              .withChild(new StringUpdateRow()
                                                          .withMandatoryValue(false)
                                                          .withName("Postal Code")
                                                          .withField("postal")
                                                          .withKey("postal"))
                                                

                                          )
                                          .withChild(new BoxDisplayRow().withBorder(true)
                                            .withWidth("7")
                                            .withChild(new TitleDisplayRow().withTitle("Drivers License Details").withSubtitle(true))
                                            .withChild(new StringListUpdateRow()
                                                            .withOptions(ContentUtils.LICENSES())
                                                            .withField("Class")
                                                            .withName("Class")
                                                            .withKey("class"))
                                            .withChild(new DateUpdateRow()
                                                          .withMandatoryValue(false)
                                                          .withName("Expiry Date")
                                                          .withField("expiry")
                                                          .withKey("expire"))
                                          )
                            )
                          )

      this.viewFooterData.push(new BoxDisplayRow()
                  .withBorder(true)
                  .withChild(
                    new RowDisplayRow()
                      .withChild(new StringDisplayRow().withWidth("3"))
                      .withChild(new ButtonDisplayRow().withWidth("3").withName("Cancel").withField("DetailsClose"))
                      .withChild(new StringDisplayRow().withWidth("3"))
                      .withChild(new ButtonDisplayRow().withWidth("3").withName("Save").withField("Save").withBackgroundPrimaryAction())
                      .withChild(new StringDisplayRow().withWidth("3"))
                  )
              );
      this.isReady = true;
    },

    onClick: function(event) {
      var modal = document.getElementById("detailsModal");
      if (event.target == modal) {
        var data = {};
        data['id'] = this.inspectionId;
        data['field'] = "DetailsClose"
        this.$emit('click', data);
      }
    },
    
    withButtonPressed: function(buttonData) {
      if (buttonData["field"] === "DetailsClose") {
        this.$emit("withButtonPressed", buttonData);
      }
      
      if (buttonData["field"] === "Full") {
        const fullParams = {
          userId: this.Contents.baseEncode(buttonData["id"]),
        };
        this.$router.push({
          name: ConstUtils.ROUTES.INSPECTION.LIST,
          query: fullParams,
        });
      }

      if (buttonData["field"] === "Save") {
        this.save();
      }
    },

    areRequirementsMet: function() {
      this.showMissingRequirements = false;
      var met = ContentUtils.hasValue(this.kvps, "value");
      if (!met) {
        this.showMissingRequirements = true;
        return false;
      }
      return met;
    },

    save: function() {
      if (!this.areRequirementsMet()) {
        return false;
      }
      
      //var id = ContentUtils.baseDecode(this.inspectionId);

    },
    
    withValue: function(valueData) {
      if (valueData) {
        //
      }
      // this.kvps[valueData["field"]] = valueData["value"];
    },

    displayProgress: function(msg) {
      this.callback(msg);
    },

    callback: function(msg) {
      if (msg) {
        //
      }
    },
    
  },
}
</script>