<template>
  <Row v-if="isReady">
    <Column :width="15">
      <div id="detailsModal" class="rc-modal-dialog-centered" :key="redraw">
        <!-- Modal content -->
        <div class="rc-modal-content-dialog-small-centered">
          <div class="rc-modal-header-dialog">
            <Box>
              <WorkingDisplayRow v-if="this.showWorking"></WorkingDisplayRow>

              <Row 
                v-for="(row, rowIndex) in viewHeaderData" 
                :key="rowIndex" >
                  <CellFactory 
                    :rowData="row.data"
                    :showMissingRequirements="showMissingRequirements"
                    v-on:withValue="withValue"
                    v-on:withButtonPressed="withButtonPressed"
                    />
              </Row>
            </Box>
          </div>

          <div class="rc-modal-body-dialog">
            <div class="rc-modal-body-inner-dialog rc-border-thin">
              <Box>
              <Row 
                v-for="(row, rowIndex) in viewBodyData" 
                :key="rowIndex" >
                  <CellFactory 
                    :rowData="row.data"
                    :showMissingRequirements="showMissingRequirements"
                    v-on:withValue="withValue"
                    v-on:withButtonPressed="withButtonPressed"
                    />
              </Row>
            </Box>
            </div>
          </div>

          <div class="rc-modal-footer-dialog">
            <Box>
              <Row 
                v-for="(row, rowIndex) in viewFooterData" 
                :key="rowIndex" >
                  <CellFactory 
                    :rowData="row.data"
                    :showMissingRequirements="showMissingRequirements"
                    v-on:withValue="withValue"
                    v-on:withButtonPressed="withButtonPressed"
                    />
              </Row>
            </Box>
          </div>

        </div>
      </div>
    </Column>
  </Row>
</template>

<script>

import { mapActions, mapGetters } from 'vuex';

import ConnectionUtils from '@/utils/ConnectionUtils.js';
import ConstUtils from '@/utils/ConstUtils.js';
import ContentUtils from '@/utils/ContentUtils.js';
import StringUtils from '@/utils/StringUtils.js';

import MC from "@/domain/session/MC.js";

import Column from "@/portals/shared/library/box/Column.vue";
import Row from "@/portals/shared/library/box/Row.vue";
import Box from "@/portals/shared/library/box/Box.vue";
import CellFactory from "@/portals/shared/cell/CellFactory.vue";

import TitleDisplayRow from "@/portals/shared/cell/title/TitleDisplayRow";
import ButtonDisplayRow from "@/portals/shared/cell/button/ButtonDisplayRow";
import BoxDisplayRow from "@/portals/shared/cell/box/BoxDisplayRow";
import RowDisplayRow from "@/portals/shared/cell/row/RowDisplayRow";
import StringDisplayRow from "@/portals/shared/cell//string/StringDisplayRow";
import SpacerDisplayRow from '@/portals/shared/cell/spacer/SpacerDisplayRow';
import ErrorDisplayRow from "@/portals/shared/cell/error/ErrorDisplayRow";

import WorkingDisplayRow from '@/portals/shared/views/working/WorkingDisplayRow.vue';
import EmailImageDisplayRow from "@/portals/shared/cell/image/email/EmailImageDisplayRow";

export default {
  name: "employee-list-restore-confirm-dialog-view",
  components: {
    Column, Row, 
    Box,
    CellFactory,
    WorkingDisplayRow,
  },
  props: {
    userId: { type: String, default: "" },
    isAdmin: { type: Boolean, default: false },
  },
  data() {
    return {
      redraw: 1,
      
      MC: new MC(),
      Strings: StringUtils,
      Contents: ContentUtils,
      Consts: ConstUtils,

      showMissingRequirements: false,
      viewHeaderData: [],
      viewBodyData: [],
      viewFooterData: [],

      isReady: false,
      showWorking: false,
    };
  },
  computed: {
    ...mapGetters([
                   'auth_connected',
                   'auth_socket_status',
                   'signin_event',
                   'auth_client',
                   'domain',
                   ]),
  },
  watch: {
    auth_connected() {
      ConnectionUtils.sendSignInEvent(this);
    },
    auth_socket_status() {
      ConnectionUtils.displayStatus(this);
    },
    signin_event() {
      ConnectionUtils.createDomainSession(this);
    }
  },
  mounted: function () {
    ConnectionUtils.ensureConnection(this);
  },
  methods: {
    ...mapActions([
                   'AuthStorage_updateCompany',
                   'addListenerAction',
                   'removeListenerAction',
                   'updateDomain',
                   ]),

    start: function() {
      this.setup();
      this.isReady = true;
    },

    setup: function() {
      window.onclick = this.onClick;
      
      this.viewHeaderData = [];
      this.viewBodyData = [];
      this.viewFooterData = [];

      this.viewHeaderData.push(new TitleDisplayRow().withTitle("Restore User"));
      var user = this.domain.users().findById(this.Contents.baseDecode(this.userId));
      // var company = this.domain.companys().findById(this.Contents.baseDecode(this.$route.params.operatorId));

      if (this.error) {
        var box = new BoxDisplayRow().withBorder(true);
        box
          .withChild(new RowDisplayRow()
            .withChild(new SpacerDisplayRow().withWidth("1"))
            .withChild(new ErrorDisplayRow().withWidth("13").withError(this.error))
            .withChild(new SpacerDisplayRow().withWidth("1"))
          );
        this.viewBodyData.push(box);
      }

      this.viewBodyData.push(new BoxDisplayRow().withChild(new RowDisplayRow()
                              .withChild(new SpacerDisplayRow().withWidth("1"))
                              .withChild(new BoxDisplayRow()
                                              .withWidth("13")
                                              .withChild(new EmailImageDisplayRow())
                                              .withChild(new StringDisplayRow().withValue("This will restore the user and allow them to sign into the App with their previous password.").withFontSize("xlarge"))
                                              .withChild(new SpacerDisplayRow())
                                              .withChild(new StringDisplayRow().withValue(user.fullName()).withFontSize("xxxlarge"))
                                              .withChild(new SpacerDisplayRow())
                                              .withChild(new StringDisplayRow().withValue("Are you sure you want to Restore this user?").withFontSize("xlarge"))
                                            )
                              .withChild(new SpacerDisplayRow().withWidth("1"))
                            )
                          )

      this.viewFooterData.push(new BoxDisplayRow()
                  .withBorder(true)
                  .withChild(
                    new RowDisplayRow()
                      .withChild(new StringDisplayRow().withWidth("3"))
                      .withChild(new ButtonDisplayRow().withWidth("3").withName("Back").withField("DetailsClose"))
                      .withChild(new StringDisplayRow().withWidth("3"))
                      .withChild(new ButtonDisplayRow().withWidth("3").withName("Restore").withField("Restore").withBackgroundPrimaryAction())
                      .withChild(new StringDisplayRow().withWidth("3"))
                  )
              );
    },

    sendingScreen() {
      this.viewBodyData = [];
      this.viewBodyData.push(new BoxDisplayRow().withChild(new RowDisplayRow()
                              .withChild(new SpacerDisplayRow().withWidth("2"))
                              .withChild(new BoxDisplayRow()
                                    .withWidth("11")
                                    .withChild(new StringDisplayRow()
                                                  .withMandatoryValue(true)
                                                  .withValue("Restoring User"))
                                  )
                              .withChild(new SpacerDisplayRow().withWidth("2"))
                            )
                          )
      this.viewFooterData = [];
      this.paint();
    },

    doneScreen() {
      this.viewBodyData = [];
      this.viewBodyData.push(new BoxDisplayRow().withChild(new RowDisplayRow()
                              .withChild(new SpacerDisplayRow().withWidth("2"))
                              .withChild(new BoxDisplayRow()
                                    .withWidth("11")
                                    .withChild(new StringDisplayRow()
                                                  .withMandatoryValue(true)
                                                  .withValue("Done!"))
                                  )
                              .withChild(new SpacerDisplayRow().withWidth("2"))
                            )
                          )
      this.viewFooterData = [];
      this.paint();
    },

    onClick: function(event) {
      var modal = document.getElementById("detailsModal");
      if (event.target == modal) {
        this.goBack();
      }
    },
    
    withButtonPressed: function(buttonData) {
      if (buttonData["field"] === "DetailsClose") {
        this.$emit("withButtonPressed", buttonData);
      }
      
      if (buttonData["field"] === "Restore") {
        this.send();
      }
    },

    send: function() {
      this.sendingScreen();
      var context = {
        view: this
      };

      var user = this.domain.users().findById(this.Contents.baseDecode(this.userId));
      var company = this.domain.companys().findById(this.Contents.baseDecode(this.$route.params.operatorId));
      var employee = this.domain.employees().findForCompanyAndUser(company, user);

      var event = this.domain.events().employees().restore(employee);
      event.send(this.doneListener, context);
    },

    doneListener: function(response, context) {
      if (!response || !context) {
        return;
      }
      if (response.error()) {
        this.error = response.error();
        this.setup();
        this.paint();
      } else {
        this.doneScreen();
        setTimeout(this.goBackRemoved, 2000);
      }
    },

    goBackRemoved: function() {
      this.goBack(true);
    },

    goBack: function(remove) {
      var data = {};
      data['id'] = this.inspectionId;
      data['field'] = "DetailsClose"
      data['removed'] = remove;
      this.$emit('click', data);
    },

    paint: function() {
      this.redraw++;
    },

    withValue: function(valueData) {
      this.$emit("withValue", valueData);
    },

    displayProgress: function(msg) {
      this.callback(msg);
    },

    callback: function(msg) {
      if (msg) {
        //
      }
    },
    
  },
}
</script>