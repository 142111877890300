<template>

  <Row :showBorder="false">
    <Column :width="15" :showBorder="showBorder">
      <Box>
        <Row>

          <Column :width="2">
            <Button :disabled="page == 'details'" v-on:click="details()">Details</Button>
          </Column>

          <Column :width="2">
            <Button v-if="isEmployeeInvited" :disabled="displayProgress" v-on:click="resend()">
              Resend Invite Email
            </Button>
          </Column>

          <Column :width="2">
            <Button v-if="isEmployeePending" :disabled="displayProgress" v-on:click="invite()">
              Send Invite Email
            </Button>
          </Column>
          
          <Column :width="2">
            <Button :disabled="page == 'inspections'" v-on:click="inspections()">Inspections</Button>
          </Column>

          <Column :width="2">
            <Button :disabled="page == 'hours'" v-on:click="hours()">Hours of Service</Button>
          </Column>

          <Column :width="1" />

          <Column :width="2">

            <Button v-if="isActiveOrInvited" v-on:click="remove()">
              Remove
            </Button>

            <Button v-else v-on:click="restore()">
              Restore
            </Button>

          </Column>

          <Column :width="2">
            <Button :disabled="page == 'edit'" v-on:click="update()">Edit</Button>
          </Column>

        </Row>

        <ProgressRow :display="displayProgress" title="Sending" />

        <ConfirmRemove @closeConfirmed="closeConfirmed" @closeRejected="closeRejected" v-if="showConfirmRemove"
          v-bind:user="this.removeEmployee" />

        <ConfirmRestore @closeConfirmed="closeConfirmed" @closeRejected="closeRejected" v-if="showConfirmRestore"
          v-bind:user="this.removeEmployee" />
      </Box>
    </Column>
  </Row>

</template>
          
<script>
import { mapGetters } from 'vuex';

import ConstUtils from "@/utils/ConstUtils.js";

import Box from "@/portals/shared/library/box/Box.vue";
import Row from "@/portals/shared/library/box/Row.vue";
import Column from "@/portals/shared/library/box/Column.vue";
import Button from "@/portals/shared/library/button/Button.vue";

import ProgressRow from '@/components/row/ProgressRow.vue'

import ConfirmRemove from "@/views/portal/employees/remove/ConfirmRemoveEmployee.vue";
import ConfirmRestore from "@/views/portal/employees/remove/ConfirmRestoreEmployee.vue";

export default {
  name: "portal-operator-employee-menu",
  components: {
    Box, Row, Column, Button,
    ConfirmRemove, ConfirmRestore,
    ProgressRow,
  },
  data() {
    return {
      Consts: ConstUtils,
      showConfirmRemove: false,
      showConfirmRestore: false,
      removeEmployee: null,
      displayProgress: false,

    }
  },
  props: {
    userId: { type: String, default: "" },
    page: { type: String, default: "" },
    showBorder: { type: Boolean, default: false },
    isAdmin: { type: Boolean, default: false },
  },
  computed: {
    ...mapGetters([
      'auth_connected',
      'domain',
    ]),
    userData: function () {
      return this.user.data;
    },
    user: function () {
      return this.employee.user().find();
    },

    employeeData: function () {
      return this.employee.data;
    },
    employee: function () {
      return this.domain.employees().findById(this.theId);
    },
    theId: function () {
      if (this.isAdmin) {
        return this.userId;
      }
      return this.id ? this.id : this.userId;
    },
    id: function () {
      return this.$route.params["id"];
    },
    isActiveOrInvited: function () {
      var state = this.employee.state();
      return state === 'active' || state === 'invited' || state === "notinvited";
    },

    isEmployeeInvited: function () {
      var state = this.employee.state();
      return state === 'invited';
    },
    isEmployeePending: function () {
      var state = this.employee.state();
      return state === 'notinvited';
    },
  },
  methods: {
    hours: function () {
      var params = {
        id: this.theId,
      };
      this.$router.push({
        name: ConstUtils.ROUTES.EMPLOYEE.HOURS,
        params: params,
      });
    },
    inspections: function () {
      var params = {
        id: this.theId,
      };
      this.$router.push({
        name: ConstUtils.ROUTES.EMPLOYEE.INSPECTIONS,
        params: params,
      });
    },
    license: function () {
      var params = {
        id: this.theId,
      };
      this.$router.push({
        name: ConstUtils.ROUTES.EMPLOYEE.LICENSE,
        params: params,
      });
    },
    details: function () {
      var params = {
        id: this.theId,
      };

      this.$router.push({
        name: ConstUtils.ROUTES.EMPLOYEE.DETAILS,
        params: params,
      });
    },
    update: function () {
      var params = {
        id: this.theId,
      };

      this.$router.push({
        name: ConstUtils.ROUTES.EMPLOYEE.EDIT,
        params: params,
      });
    },
    list: function () {
      if (this.page == 'list') {
        this.$emit('action', 'refresh');
      } else {
        this.$router.push({
          name: ConstUtils.ROUTES.EMPLOYEE.LIST,
        });
      }
    },

    closeConfirmed: function () {
      this.removeEmployee = null;
      this.showConfirmRemove = false;
      this.showConfirmRestore = false;
      this.list();
    },

    closeRejected: function () {
      this.removeEmployee = null;
      this.showConfirmRemove = false;
      this.showConfirmRestore = false;
    },

    invite: function() {
      if (this.displayProgress) {
        return;
      }
      this.displayProgress = true;
      var event = this.domain.events().employeeResend(this.user);
      event.send(this.inviteListener);
    },
    
    inviteListener: function (event) {
      this.displayProgress = false;
      if (event.error()) {
        this.error = event.error();
      } else {
        this.$emit("action", "refresh");
      }
    },
    
    resend: function () {
      if (this.displayProgress) {
        return;
      }
      this.displayProgress = true;
      var event = this.domain.events().employeeResend(this.user);
      event.send(this.resendListener);
    },

    resendListener: function (event) {
      this.displayProgress = false;
      window.scrollTo(0, 0);
      if (event.error()) {
        this.error = event.error();
      }
    },

    remove: function () {
      this.removeEmployee = this.user.data;
      this.removeEmployee['employee'] = this.employeeData
      this.showConfirmRemove = true;
    },

    restore: function () {
      this.removeEmployee = this.user.data;
      this.removeEmployee['employee'] = this.employeeData
      this.showConfirmRestore = true;
    },
  }
}

</script>